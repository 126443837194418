import React from 'react';
import PropTypes from 'prop-types';
import { useLatticeContext } from './Lattice';

const LatticeDropdown = (props) => {
    const { name, component, ...rest } = props;
    const { handleDropdownOnChange, values } = useLatticeContext();
    return React.createElement(component, {
        name,
        onChange: handleDropdownOnChange,
        value: values[name],
        ...rest,
    });
};

LatticeDropdown.propTypes = {
    component: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
    name: PropTypes.string.isRequired,
};

export default LatticeDropdown;
