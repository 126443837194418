/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

const LatticeContext = React.createContext({
    values: {},
    errors: {},
    handleOnChange: () => {},
    handleCheckboxOnChange: () => {},
    handleDropdownOnChange: () => {},
    handleRadioOnChange: () => {},
    validateWithYup: () => {},
    setIsSubmitting: () => {},
    setIsValidating: () => {},
    setError: () => {},
    clearError: () => {},
    isSubmitting: false,
    isValidating: false,
});

const useLatticeContext = () => {
    const context = useContext(LatticeContext);
    if (context === undefined) {
        throw new Error('useLattice requires a LatticeProvider in the upper scope');
    }
    return context;
};

class Lattice extends React.Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
        initialValues: PropTypes.shape({}),
        storageKey: PropTypes.string,
        withPersistence: PropTypes.string,
    };

    static defaultProps = {
        initialValues: null,
        storageKey: 'lattice',
        withPersistence: '',
    };

    constructor(props) {
        super(props);
        this.state = this.initializeState(props);
    }

    componentDidUpdate() {
        const { withPersistence } = this.props;
        if (withPersistence) this.debouncedLocalStorageUpdate();
    }

    initializeState = (props) => {
        const { initialValues, storageKey, withPersistence } = props;
        const defaultState = {
            values: {},
            errors: {},
            isSubmitting: false,
            isValidating: false,
        };

        if (initialValues) {
            return _.merge(defaultState, initialValues);
        }

        if (withPersistence === 'local') {
            try {
                return _.merge(defaultState, JSON.parse(window.localStorage.getItem(storageKey)));
            } catch (e) {
                console.log('No local Lattice state found');
            }
        } else if (withPersistence === 'session') {
            try {
                return _.merge(defaultState, JSON.parse(window.sessionStorage.getItem(storageKey)));
            } catch (e) {
                console.log('No local Lattice state found');
            }
        }

        return defaultState;
    };

    debouncedLocalStorageUpdate = () => {
        const { storageKey, withPersistence } = this.props;
        let updater;

        if (withPersistence === 'local') {
            updater = _.debounce(
                () => window.localStorage.setItem(storageKey, JSON.stringify(_.pick(this.state, ['values', 'errors']))),
                1000,
                { trailing: true }
            );
        } else if (withPersistence === 'session') {
            updater = _.debounce(
                () => window.sessionStorage.setItem(storageKey, JSON.stringify(_.pick(this.state, ['values', 'errors']))),
                1000,
                { trailing: true }
            );
        }

        updater();
    };

    setIsSubmitting = (isSubmitting) => {
        this.setState({ isSubmitting });
    };

    setIsValidating = (validating) => {
        this.setState({ isValidating: validating });
    };

    setError = (key, message) => {
        this.setState((state) => {
            const { errors } = state;
            errors[key] = message;
            return { errors };
        });
    };

    clearError = (key) => {
        this.setState((state) => {
            const { errors } = state;
            const errorsWithoutKey = _.omit(errors, key);
            return { errors: { ...errorsWithoutKey } };
        });
    };

    // If they underlying input component you want to use doesn't natively provide
    // a data argument, you will have to provide bindings yourself until I've
    // updated this to be able to parse the raw event as well.
    handleOnChange = (e, data) => {
        const { name, value } = data;

        this.setState((state) => {
            const { values } = state;
            values[name] = value;
            return { values };
        });
    };

    // I changed these handlers to accept a `data` argument for easy compatibility with some of semantic uis
    // inputs. At first I thought this was probably not smart for reusability but actually enforcing this
    // paradigm makes a lot of sense. Mainly because it avoids having to think about Reacts synthetic event pool
    handleRadioOnChange = (e, data) => {
        const { name, value } = data;

        this.setState((state) => {
            const { values } = state;
            values[name] = value;
            return { values };
        });
    };

    // Currently these handlers are mostly just duplicates. I've just separated them in anticipation
    // of possible differences needed in the future.
    handleDropdownOnChange = (e, data) => {
        const { name, value } = data;

        this.setState((state) => {
            const { values } = state;
            values[name] = value;
            return { values };
        });
    };

    handleCheckboxOnChange = (e, data) => {
        const { name, checked } = data || e.target;
        this.setState((state) => {
            const { values } = state;
            values[name] = checked;
            return { values };
        });
    };

    validateWithYup = async (schema) => {
        this.setState({ isValidating: true });
        const { values } = this.state;
        const schemaKeys = Object.keys(schema.fields);
        try {
            // yup schema validate() always resolves to true, otherwise it
            // throws an error containing the validation errors. If validation
            // passes, we need to make sure to delete any key we validated from
            // our errors state.
            const valid = await schema.validate(values, { abortEarly: false });
            if (valid) {
                this.setState((state) => {
                    const { errors } = state;
                    const errorsWithNoSchemaKeys = _.omit(errors, schemaKeys);
                    return { errors: { ...errorsWithNoSchemaKeys } };
                });
            }
            this.setState({ isValidating: false });
            return schemaKeys;
        } catch (error) {
            console.log('Yup validation', error)
            // If there are errors we need to do two things. Add those errors
            // to our Lattice errors state and remove any keys from our current
            // error state that might be left over from a previous failed
            // validation attempt if they no longer have errors.
            const fieldErrors = {};
            error.inner.map(field => (fieldErrors[field.path] = field.message));
            this.setState((state) => {
                const { errors } = state;
                const errorsWithNoSchemaKeys = _.omit(errors, schemaKeys);
                const newErrors = { ...errorsWithNoSchemaKeys, ...fieldErrors };
                return { errors: newErrors };
            });
            this.setState({ isValidating: false });
            return false;
        }
    };

    // You might think 'why expose all this stuff through LatticeContext and through a render prop at the same time?'
    // The answer is there's no performance cost and it gives the developer more flexibility to use
    // the implementation paradigm they find most comfortable.
    render() {
        const { children } = this.props;
        const { isSubmitting, isValidating } = this.state;
        return (
            <LatticeContext.Provider
                value={{
                    ...this.state,
                    handleOnChange: this.handleOnChange,
                    handleCheckboxOnChange: this.handleCheckboxOnChange,
                    handleDropdownOnChange: this.handleDropdownOnChange,
                    handleRadioOnChange: this.handleRadioOnChange,
                    validateWithYup: this.validateWithYup,
                    setIsSubmitting: this.setIsSubmitting,
                    setIsValidating: this.setIsValidating,
                    setError: this.setError,
                    clearError: this.clearError,
                    isSubmitting,
                    isValidating,
                }}
            >
                {typeof children === 'function'
                    ? children(
                        isSubmitting,
                        isValidating,
                        this.validateWithYup,
                    )
                    : children}
            </LatticeContext.Provider>
        );
    }
}

export { Lattice, LatticeContext, useLatticeContext };
