import React from 'react';
import PropTypes from 'prop-types';
import { useLatticeContext } from './Lattice';

const LatticeField = (props) => {
    const {
        type, name, component, changeReducer, children, ...rest
    } = props;
    // Hooks make our consumers cleaner and more readable
    const { errors, handleOnChange, values } = useLatticeContext();

    let derivedOnChangeHandler = () => {};

    // The changeReducer prop is a function which can be passed to LatticeField to
    // modify the event data in transit, allowing the implementation to customize
    // the input value on change before it reaches Lattice state. This will be
    // useful for things like input masking. The changeReducer is passed both
    // the new data and full current set of values from Lattice context.
    if (typeof changeReducer === 'function') {
        derivedOnChangeHandler = (e, data) => {
            const reducedData = changeReducer(data, values);
            handleOnChange(e, reducedData);
        };
    } else {
        derivedOnChangeHandler = handleOnChange;
    }

    if (component) {
        return React.createElement(component, {
            name,
            type,
            error: !!errors[name],
            onChange: derivedOnChangeHandler,
            value: values[name],
            ...rest,
        }, children);
    }

    return (
        <input
            type={type}
            name={name}
            onChange={derivedOnChangeHandler}
            value={values[name]}
            {...rest}
        />
    );
};

LatticeField.propTypes = {
    component: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    name: PropTypes.string.isRequired,
    type: PropTypes.string,
    changeReducer: PropTypes.func,
};

LatticeField.defaultProps = {
    component: null,
    type: 'text',
    changeReducer: null,
};

export default LatticeField;
