import React, { useState, useEffect, useRef } from 'react';

// setInterval() is very annoying to use in hooks without breaking things or introducing convolution.
// We want a version of setInterval that can have both its callback and delay updated dynamically in response
// to state changes without causing unintended behavior.
// This hook is from a post by Dan Abramov:https://overreacted.io/making-setinterval-declarative-with-react-hooks/
// This connects setInterval to the component lifecycle.
const useInterval = (callback, delay) => {
    const savedCallback = useRef();

    // We need a mutable ref version of our callback that can be updated because in a normal
    // setInterval, the callback is snapshotted and all you can do is clear the interval.
    // By having the interval call a reference to the current function, the interval can
    // track updates to the callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        const tick = () => {
            savedCallback.current();
        };
        if (delay !== null) {
            const id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
};

export default useInterval;
