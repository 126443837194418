import React from 'react';
import PropTypes from 'prop-types';
import { useLatticeContext } from './Lattice';

const LatticeCheckbox = (props) => {
    const {
        component, name, label, ...rest
    } = props;
    const { handleCheckboxOnChange, values } = useLatticeContext();

    if (component) {
        return React.createElement(component, {
            name,
            label,
            onChange: handleCheckboxOnChange,
            value: values[name],
            checked: values[name],
            ...rest,
        });
    }

    return (
        <label>
            <input
                type="checkbox"
                name={name}
                onChange={handleCheckboxOnChange}
                value={values[name]}
                checked={values[name]}
                {...rest}
            />
            {label}
        </label>
    );
};

LatticeCheckbox.propTypes = {
    component: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    name: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.node]),
};

LatticeCheckbox.defaultProps = {
    component: null,
    label: null,
};

export default LatticeCheckbox;
