import React from 'react';
import PropTypes from 'prop-types';

const LatticeSlide = ({
    activeIndex, maxIndex, next, prev, on, children, setStep,
}) => {
    if (typeof window !== 'undefined') {
        return on
            ? children({
                activeIndex,
                maxIndex,
                next,
                prev,
                setStep,
                on,
            })
            : null;
    }
    return null;
};

LatticeSlide.displayName = 'LatticeSlide';
LatticeSlide.propTypes = {
    stepId: PropTypes.string.isRequired,
};

export default LatticeSlide;
