/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import React from 'react';
import useObjectSessionStorage from './useSessionStorage';

const usePegasusVariants = (suite) => {
    const [abtests, setAbtests, clear] = useObjectSessionStorage('peg_v', {});

    if (abtests && abtests.tests) return abtests.tests;
    const roll = (test) => {
        const rollInstance = Math.floor(Math.random() * 100) + 1;
        let current = 0;

        for (const variantName in test) {
            current += test[variantName].weight;
            if (current >= rollInstance) return variantName;
        }
        return 'default';
    };

    const active = {};

    if (suite) {
        for (const testName in suite) {
            const test = suite[testName];
            if ('variations' in test === false || testName in active) continue;
            const variantName = roll(test.variations);
            active[testName] = variantName;
        }
    }
    // TODO: Fix query param test control
    // if (typeof window !== 'undefined') {
    //     const queryParams = parse(window.location.search);
    //     const paramRegex = /^abtest_/;
    //     for (const paramName in queryParams) if (paramRegex.test(paramName)) this.active[paramName.replace(paramRegex, '')] = queryParams[paramName];
    // }
    const tests = [];
    for (const testName in active) {
        tests.push({
            name: testName,
            variation: active[testName],
        });
    }

    setAbtests({ tests });

    return abtests.tests;
};

export default usePegasusVariants;
