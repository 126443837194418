import React from 'react';
import PropTypes from 'prop-types';
import { useLatticeContext } from './Lattice';

// Supports radio button behavior in the lattice form.
// Radio buttons will be grouped which share the same 'name' prop
// and the 'value' prop controls what value is put in state when
// selected.
const LatticeRadio = (props) => {
    const {
        component, name, label, value, ...rest
    } = props;

    const { handleRadioOnChange, values } = useLatticeContext();

    return React.createElement(component, {
        name,
        label,
        radio: true,
        onChange: handleRadioOnChange,
        value,
        checked: values[name] === value,
        ...rest,
    });
};

LatticeRadio.propTypes = {
    component: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
};

LatticeRadio.defaultProps = {
    label: null,
};

export default LatticeRadio;
