import React, { useState } from 'react';
import _ from 'lodash';
// import { getCookie, setCookie } from '../utils/DOMHelper';
// import { Log } from '../utils';

const PREFIX = 'pegasus.';
const uniqueEventIndex = {
    pageViewResponse: 'timestamp',
    applicationDecision: 'uuid',
};

const usePegasusDataLayer = (websiteId, environment) => {
    const [decisionIds, setDecisionIds] = useState([]);

    function getCookie(key) {
        const match = typeof document !== 'undefined' && document.cookie.match(new RegExp(`(?:^|;)\\s*${key}=([^;]*)(?:;|$)`));
        return (match instanceof Array && match[1]) || '';
    }

    const setCookie = (key, value, {
        expires, path, domain, secure,
    } = {}) => {
        let cookie = `${key}=${value}`;

        if (expires) {
            cookie
                += expires instanceof Date ? `; expires=${expires.toUTCString()}` : `; max-age=${expires.toString()}`;
        }

        if (path) cookie += `; path=${path}`;
        if (domain) cookie += `; domain=${domain}`;
        if (secure) cookie += '; secure';
        if (typeof document !== 'undefined') document.cookie = cookie;
    };

    const toDataLayer = (...items) => {
        if (process.env.GATSBY_BUILD_ENV !== 'prod') {
            console.log(
                `[GTM] ${items.length > 1 ? `${items.length} items ` : ''}pushed to dataLayer:`,
                JSON.stringify(items, null, 4)
            );
        } else if (typeof window !== 'undefined') {
            window.dataLayer = window.dataLayer || [];

            _.forOwn(items, (item) => {
                dataLayer.push(item);
            });
        }
    };

    const sendFormSubmit = (formName, data) => {
        toDataLayer({
            event: 'form.submit',
            formName,
        });
    };

    const sendOLPSubmit = (decisionId) => {
        if (decisionId && decisionId in decisionIds) {
            console.error(`[GTM] olp.submit event already raised with decisionId ${decisionId}`);
            return;
        }

        if (decisionId) {
            setDecisionIds(state => state.push(decisionId));
        }

        toDataLayer({
            event: 'olp.submit',
            decisionId,
        });
    };

    const sendPageviewData = async (pageViewResponse) => {
        if (pageViewResponse) {
            const pageViewData = {};

            if (pageViewResponse.utmTracking) {
                pageViewData.utmTracking = pageViewResponse.utmTracking;
            }

            if (pageViewResponse.cpanelClick) {
                pageViewData.promoId = pageViewResponse.cpanelClick.promoId;
                pageViewData.subcode = pageViewResponse.cpanelClick.subcode || null;
            }

            if (pageViewResponse.buid) {
                pageViewData.buid = pageViewResponse.buid;
            }

            if (pageViewResponse.applicant && pageViewResponse.applicant.email) {
                window.__userData = window.__userData || {};
                window.__userData.email = pageViewResponse.applicant.email;
            }

            if (pageViewResponse.applicationHash) {
                pageViewData.applicationHash = pageViewResponse.applicationHash;
            }

            if (pageViewResponse.sessionIdHash) {
                pageViewData.sessionIdHash = pageViewResponse.sessionIdHash;
            }

            toDataLayer({
                websiteId,
                environment,
                ...pageViewData,
                event: 'pageview.response',
            });

            // unique pageview

            // if (this.data.sessionIdHash && !getCookie('sessionIdHash')) {
            //     toDataLayer({
            //         ...this.data,
            //         ...pageViewData,
            //         event: 'pageview.unique',
            //     });

            //     setCookie('sessionIdHash', this.data.sessionIdHash);
            // }

            // abtests
            if (pageViewResponse.abtests instanceof Array) {
                _.forOwn(pageViewResponse.abtests, (test) => {
                    toDataLayer({
                        websiteId,
                        environment,
                        event: `abtest.${test.name}`,
                        variation: test.variation,
                    });
                });
            }
        }
        // Trust in the effect lifecycle
        // this.lastEventIndex.pageViewResponse = pageViewResponse.timestamp;
    };

    const processApplicationDecision = (applicationDecision, decision) => {
        // decision event
        if (!applicationDecision) {
            return;
        }

        const {
            decisionOutcome, uuid, publisherPayout, billedAmount,
        } = applicationDecision;
        const earnedAmount = applicationDecision.earnedAmount || null;
        const lastDecision = getCookie('decisionUuid');

        if (lastDecision && lastDecision === uuid) {
            console.debug(`[GTM] decision event ${uuid} has already been registered.`);
            return;
        }

        // semaphore
        setCookie('decisionUuid', uuid);

        const decisionData = _.assign({}, decision);
        decisionData.uuid = uuid;
        decisionData.transactionId = uuid;
        decisionData.earnedAmount = earnedAmount ? parseFloat(`${earnedAmount}`.replace(/[^0-9.]+/g, '')) : null;
        decisionData.transactionTotal = decisionData.earnedAmount;
        decisionData.publisherPayout = publisherPayout ? parseFloat(publisherPayout) : null;
        decisionData.billedAmount = billedAmount ? parseFloat(billedAmount) : null;

        if (decisionOutcome && decisionOutcome.outcomeName) {
            decisionData.outcome = decisionOutcome.outcomeName;
            const eventName = `decision.${decisionOutcome.outcomeName.replace(/\s+/g, '_')}`;

            toDataLayer({
                ...decisionData,
                event: eventName,
            });

            const decisionEventName = PREFIX + eventName;
            console.log(decisionEventName);
        }

        if (earnedAmount === null) {
            return;
        }

        toDataLayer({
            ...decisionData,
            ecommerce: {
                purchase: {
                    actionField: {
                        id: decisionData.uuid,
                        affiliation: 'Credit Loan',
                    },
                    products: [
                        {
                            name: 'PW-LOAN-COMBINED',
                            id: 'PW-LOAN-COMBINED',
                            price: decisionData.earnedAmount,
                            category: 'Loans',
                            variant: applicationDecision.appliedMultipleTimes ? 'apply-for-more' : 'initial',
                            quantity: 1,
                        },
                    ],
                },
            },
            event: 'billable',
        });
    };

    return {
        sendFormSubmit, sendOLPSubmit, processApplicationDecision, sendPageviewData,
    };
};

export default usePegasusDataLayer;
