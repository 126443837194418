import { useStaticQuery, graphql } from 'gatsby';

const useSiteMetadata = () => {
    const { site } = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                    abTests {
                        financematrix {
                            variations {
                                finance_matrix {
                                    weight
                                }
                            }
                        }
                    }
                    title
                    canonicalDomain
                    websiteId
                    tagline
                    description
                    name
                    nameLegal
                    email {
                        contact
                        media
                    }
                    companyId
                }
            }
        }
    `);

    return site.siteMetadata;
};

export default useSiteMetadata;
