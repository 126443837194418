import React, { useState } from 'react';

const useObjectSessionStorage = (storageKey, initVal) => {
    const [value, setState] = useState(() => {
        const val = sessionStorage.getItem(storageKey) || JSON.stringify(initVal);
        sessionStorage.setItem(storageKey, val);
        return val;
    });

    const item = JSON.parse(value);

    const set = (val) => {
        const stringified = JSON.stringify(val);
        setState(stringified);
        sessionStorage.setItem(storageKey, stringified);
    };

    const clear = () => {
        sessionStorage.removeItem(storageKey);
    };

    return [item, set, clear];
};

export default useObjectSessionStorage;
