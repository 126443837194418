import React from 'react';
import { PegasusConduit } from './src/components/PegasusConduit';
import { Lattice } from './src/components/Lattice';

// Lets see if the new PegasusConduit can handle this so we never have to deal with a race condition
// export const onRouteUpdate = () => {
//     postPageView();
// };

export const wrapPageElement = ({ element, props }) => {
    const { location } = props;
    return (
        <PegasusConduit location={location} baseUrl={process.env.GATSBY_PEGASUS_BASE_URL} apiUrl={process.env.GATSBY_ACQUIRE_BASE_URL}>
            <Lattice withPersistence="session">{element}</Lattice>
        </PegasusConduit>
    );
};

export const disableCorePrefetching = () => true;
