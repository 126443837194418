import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import queryString from 'querystring';
import axios from 'axios';
import useInterval from '../Hooks/useInterval';
import useSiteMetadata from '../Hooks/useSiteMetadata';
import usePegasusVariants from '../Hooks/usePegasusVariants';
import usePegasusDataLayer from '../Hooks/usePegasusDataLayer';

// This component is probably overkill but at the very least it makes for more development friendly implementation. It
// also avoids creating mutual dependence between our form management components and the Pegasus api.
const PegasusContext = React.createContext({
    applicant: () => {},
    pageview: () => {},
    applicantLoading: false,
    pageviewLoading: false,
    datapackage: () => {},
    decision: () => {},
    expiration: {},
    resetExpiration: () => {},
    pageviewResult: {},
    applicantResult: {},
    unsubscribe: () => {},
    redirectDecisionUrl: '',
});

const usePegasusConduit = () => {
    const context = useContext(PegasusContext);
    if (context === undefined) {
        throw new Error('usePegasusConduit requires a PegasusConduit in the upper scope');
    }
    return context;
};

const PegasusConduit = (props) => {
    const [decisionIds, setDecisionIds] = useState([]);
    const [expiration, setExpiration] = useState({
        renewed: '',
        redirect: '',
        resumeId: '',
    });
    const [pageviewResult, setPageviewResult] = useState({});
    const [applicantResult, setApplicantResult] = useState({});
    const [applicantLoading, setApplicantLoading] = useState(false);
    const [pageviewLoading, setPageviewLoading] = useState(false);
    const siteMetadata = useSiteMetadata();
    const testSuite = siteMetadata.abTests;
    const { websiteId, companyId } = siteMetadata;
    const { sendPageviewData } = usePegasusDataLayer(websiteId, process.env.GATSBY_BUILD_ENV);

    // Solve for silly object key issue
    if (Object.keys(testSuite).includes('financematrix')) {
        testSuite['finance-matrix'] = testSuite.financematrix;
        delete testSuite.financematrix;
    }

    const abtests = usePegasusVariants(testSuite);
    const { baseUrl, apiUrl, location } = props;
    const client = axios.create({
        baseURL: baseUrl,
        withCredentials: true,
        timeout: 45000,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            websiteId: 26,
            pathname: (typeof window !== 'undefined' && window.location.pathname) || '',
            referrer: (typeof document !== 'undefined' && document.referrer) || '',
        },
    });

    const apiClient = axios.create({
        baseURL: apiUrl,
    });

    const redirectDecisionUrl = `${baseUrl}/redirect/decision`;

    const pageview = async (window) => {
        setPageviewLoading(() => true);
        const reservedQueryParams = ['entityData', 'formSubmitResponse'];
        const pageView = {};
        pageView.browser = {};

        pageView.baseUrl = `${location.protocol}//${location.host}`;
        pageView.pathname = location.pathname + (location.internalHash || location.hash);
        pageView.hostname = location.hostname;
        pageView.timestamp = Math.round(Date.now() / 1000);
        pageView.referrer = document.referrer;
        if (navigator) {
            pageView.browser.userAgent = navigator.userAgent;
            pageView.browser.appCodeName = navigator.appCodeName;
            pageView.browser.name = navigator.appName;
            pageView.browser.version = navigator.appVersion;
            pageView.browser.cookiesEnabled = navigator.cookieEnabled;
            pageView.browser.language = navigator.language;
            pageView.browser.onLine = navigator.onLine;
            pageView.browser.platform = navigator.platform;
        }

        pageView.browser.windowWidth = window.innerWidth;
        pageView.browser.windowHeight = window.innerHeight;

        if (screen) {
            pageView.browser.screenWidth = screen.width;
            pageView.browser.screenHeight = screen.height;
        }

        pageView.abtests = abtests;

        const includesFromArray = (str) => {
            const allowedHashes = ['#populate', '#resume'];

            return allowedHashes.reduce((accumulator, hash) => accumulator + (str.includes(hash) ? 1 : 0), 0);
        };

        // query params if the url is not already hashed
        let queryParams = {};
        if (location.hash === '' || includesFromArray(location.hash)) {
            const trimmedQuery = location.search.charAt(0) === '?' ? location.search.substring(1) : location.search;
            queryParams = queryString.parse(trimmedQuery);
            reservedQueryParams.forEach((key) => {
                delete queryParams[key];
            });
        }

        pageView.queryParams = queryParams;
        let res;
        try {
            res = await client.post('pageview/', pageView);
            setPageviewResult(res.data);
            setPageviewLoading(() => false);
            return res;
        } catch (e) {
            setPageviewLoading(() => false);
            return false;
        }
    };

    // The second argument to useEffect is an array of dependencies. The effect will only
    // fire when there is a change to one of its dependencies. [] can be used for a single
    // fire on mount.
    const { href } = location;

    useEffect(() => {
        if (typeof window !== 'undefined') pageview(window);
    }, [href]);

    // I don't like having the gtm push in this component but putting it somewhere else
    // will make the logic annying to follow. Good candidate for review later.
    useEffect(() => {
        sendPageviewData(pageviewResult);
    }, [pageviewResult]);

    const applicant = async (values) => {
        setApplicantLoading(() => true);
        const deepened = {};
        _.each(values, (value, key) => {
            _.set(deepened, key, value);
        });
        const extra = {
            successRedirect: 'next',
            errorRedirect: 'error',
        };
        const request = _.merge(deepened, extra);
        let res;
        try {
            res = await client.post('applicant/', request);
            setApplicantResult(res.data);
            setApplicantLoading(() => false);
            return res;
        } catch (e) {
            setApplicantLoading(() => false);
            return false;
        }
    };

    useInterval(async () => {
        const response = await client.get('session/expiration');
        const sessionExpiration = response.data;
        if (sessionExpiration.expired) {
            const postExpirationResponse = await client.post('session/expiration');
            const postExpirationData = postExpirationResponse.data;
            setExpiration((prevState) => {
                const newState = _.merge({}, prevState, {
                    renewed: postExpirationData.renewed,
                    redirect: postExpirationData.redirect,
                    resumeId: postExpirationData.resumeId || '',
                });
                return newState;
            });
        }
    }, 30000);

    const resetExpiration = () => {
        setExpiration({
            renewed: '',
            redirect: '',
            resumeId: '',
        });
    };

    const datapackage = async (
        dataPackageTag,
        validApplicantRoute,
        invalidApplicantRoute,
        deferredSubmitRoute,
        deferredSubmitOptionsRoute
    ) => {
        const request = {
            dataPackageTag,
            validApplicantRoute,
            invalidApplicantRoute,
            deferredSubmitRoute,
            deferredSubmitOptionsRoute,
        };
        try {
            const res = await client.post('submit/data-package/', request);
            if (res && res.data && res.data.decisionId) {
                setDecisionIds((current) => {
                    const newState = current.concat(res.data.decisionId);
                    return newState;
                });
            }
            return res;
        } catch (e) {
            return false;
        }
    };

    const decision = async () => {
        const timer = timeout => new Promise(resolve => setTimeout(resolve, timeout));
        let decisionData;
        do {
            await timer(5000);
            const res = await client.get('submit/decision');
            decisionData = res.data;
        } while (decisionData.submitStatus === 'try again');

        if (decisionData.submitStatus === 'done') {
            return decisionData;
        }

        if (decisionData.submitStatus === 'error') {
            throw decisionData.message;
        }
    };

    const unsubscribe = async (email) => {
        let res;
        res = axios.post(`/api/email/opt-out`, { 
            email,
            opt_out_type: "WEBSITE",
            opt_out_source: "WEBSITE",
            company_id: companyId

        }).then((resp) => {
            return true;
        }).catch((err) => {
            return false;
        });


        return { success: res };
    };

    const { children } = props;
    return (
        <PegasusContext.Provider
            value={{
                applicant,
                pageview,
                pageviewLoading,
                applicantLoading,
                decision,
                datapackage,
                expiration,
                resetExpiration,
                pageviewResult,
                applicantResult,
                unsubscribe,
                decisionIds,
                redirectDecisionUrl,
            }}
        >
            {children}
        </PegasusContext.Provider>
    );
};

PegasusConduit.propTypes = {
    children: PropTypes.node.isRequired,
    baseUrl: PropTypes.string.isRequired,
    location: PropTypes.any.isRequired,
};

export { PegasusConduit, PegasusContext, usePegasusConduit };
