import React from 'react';

class LatticeStepManager extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeIndex: 0,
            maxIndex: 0,
        };
    }

    componentWillMount() {
        const { children } = this.props;
        this.getStepsCount(children);
    }

    getStepsCount = (children) => {
        let count = 0;
        React.Children.map(children, (child) => {
            count
                += child.type.displayName === 'LatticeStepContainer'
                    ? child.props.children.length - 1
                    : 0;
        });
        this.setState({ maxIndex: count });
    };

    prev = () => {
        this.setState(state => ({
            activeIndex: state.activeIndex > 0 ? state.activeIndex - 1 : 0,
        }));
    };

    next = () => {
        this.setState(state => ({
            activeIndex:
                state.activeIndex < state.maxIndex
                    ? state.activeIndex + 1
                    : state.maxIndex,
        }));
    };

    render() {
        const { children } = this.props;
        const { activeIndex, maxIndex } = this.state;
        const enhancedChildren = React.Children.map(
            children,
            (child) => {
                if (child.type.displayName === 'LatticeStepContainer') {
                    return React.cloneElement(child, {
                        activeIndex,
                        maxIndex,
                        prev: this.prev,
                        next: this.next,
                    });
                }
                return child;
            },
            this
        );

        return <div>{enhancedChildren}</div>;
    }
}

export default LatticeStepManager;
