import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

// LatticeCarousel is designed to allow a compositional construction of a multistep form or any other multistep content,
// where each step needs access to methods that will allow navigation between steps. This component
// has two primary modes of operation. The default mode simply indexes the child steps, manages which step
// should be rendered at any given time, and exposes methods and data to the children that are relevant to
// navigating the carousel. It requires nothing other than child LatticeSlides or LatticeSteps to work.
// The second mode, which is active when the prop routingMode = 'path' or 'hash', is designed for use with Reach Router.
// In this mode LatticeCarousel must receive a step prop which controls the currently active step and a navigate
// function which should be passed in from the nearest nested Reach Router component. In this mode the functions that
// move us between steps will operate by calling navigate with a relative path equivalent to the corresponding
// LatticeSlide's stepId. It is up to the implementation to ensure that this route change is propagated back down to the
// step prop of the LatticeCarousel, causing it to render the new step.
const LatticeCarousel = (props) => {
    const {
        children, step, navigate, routingMode,
    } = props;
    const [stepIds, setStepIds] = useState([]);
    // We determine the initial step from the 'step' prop when usingRoutes is false and we use it as the active step
    // when usingRoutes is true. If its not present we'll just use the stepId of the first child.
    const [activeStep, setActiveStep] = useState(step || React.Children.toArray(children)[0].props.stepId);
    const usingRoutes = ['path', 'hash'].includes(routingMode);
    const hashed = routingMode === 'hash';

    if (usingRoutes && (!step || !navigate)) {
        throw new Error('LatticeCarousel must have step and navigate props when usingRoutes is true');
    }

    useEffect(() => {
        const steps = React.Children.map(children, (child, i) => {
            if (child.type.displayName === 'LatticeStep' || child.type.displayName === 'LatticeSlide') {
                const childRecord = {
                    stepId: child.props.stepId || `noIdStep${i}`,
                    hidden: child.props.hidden || false,
                };
                return childRecord;
            }
            return null;
        });
        setStepIds(steps);
        if (usingRoutes) {
            setActiveStep(step);
        }
    }, []);

    const prev = () => {
        const invertedStepIds = stepIds.slice(0);
        _.reverse(invertedStepIds);
        const currentIndex = _.findIndex(invertedStepIds, ['stepId', usingRoutes ? step : activeStep]);
        const prevStep = _.find(invertedStepIds, ['hidden', false], currentIndex + 1);
        if (usingRoutes) {
            navigate(hashed ? `#${prevStep.stepId}` : prevStep.stepId);
        } else {
            setActiveStep(prevStep.stepId);
        }
    };

    const next = () => {
        const currentIndex = _.findIndex(stepIds, ['stepId', usingRoutes ? step : activeStep]);
        const nextStep = _.find(stepIds, ['hidden', false], currentIndex + 1);
        if (usingRoutes) {
            navigate(hashed ? `#${nextStep.stepId}` : nextStep.stepId);
        } else {
            setActiveStep(nextStep.stepId);
        }
    };

    const setStep = (stepArg) => {
        if (usingRoutes) {
            navigate(hashed ? `#${stepArg}` : stepArg);
        } else {
            setActiveStep(stepArg);
        }
    };

    const maxIndex = _.filter(stepIds, ['hidden', false]).length;
    const activeIndex = _.findIndex(stepIds, ['stepId', usingRoutes ? step : activeStep]);
    const enhancedChildren = React.Children.map(children, (child) => {
        if (usingRoutes ? child.props.stepId === step : child.props.stepId === activeStep) {
            return React.cloneElement(child, {
                activeIndex,
                maxIndex,
                prev,
                next,
                setStep,
                on: true,
            });
        }
        return child;
    });

    return enhancedChildren;
};

LatticeCarousel.propTypes = {
    routingMode: PropTypes.oneOf(['off', 'path', 'hash']),
};

LatticeCarousel.defaultProps = {
    withRoutes: false,
    hashed: false,
    routingMode: 'off',
};

export default LatticeCarousel;
