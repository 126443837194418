import React from 'react';
import PropTypes from 'prop-types';
import { useLatticeContext } from './Lattice';

// LatticeError takes one prop 'fieldName' which corresponds
// to a field name in Lattice state. It then renders the child function passing
// passing it the error message if one is found. Otherwise it renders nothing.
const LatticeError = (props) => {
    const { fieldName, children, alwaysRender } = props;
    const { errors } = useLatticeContext();
    if (errors[fieldName] || alwaysRender) {
        return children(errors[fieldName]);
    }
    return null;
};

LatticeError.propTypes = {
    fieldName: PropTypes.string.isRequired,
    children: PropTypes.func.isRequired,
    alwaysRender: PropTypes.bool,
};

LatticeError.defaultProps = {
    alwaysRender: false,
};

export default LatticeError;
