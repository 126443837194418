module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WTJ5L8T","includeInDevelopment":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../node_modules/gatsby-plugin-rollbar/gatsby-browser.js'),
      options: {"plugins":[],"accessToken":"df476060690e48b38df1ee8c4869afa5","includeInDevelopment":false,"captureUncaught":true,"captureUnhandledRejections":true,"payload":{"environment":"development"},"enabled":true,"reportLevel":"debug"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
