// The Step portion of the compount component
// renders its children function if it is at
// the activeIndex. We use the render prop
// pattern here in the 'children as a function'
// style to allow the implementer to have access
// the step changing functions.

const LatticeStep = ({
    activeIndex,
    maxIndex,
    next,
    prev,
    on,
    children,
}) => (on ? children({
    activeIndex,
    maxIndex,
    next,
    prev,
}) : null);

LatticeStep.displayName = 'LatticeStep';

export default LatticeStep;
