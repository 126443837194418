import React from 'react';

const LatticeStepContainer = ({
    activeIndex,
    prev,
    next,
    maxIndex,
    children,
}) => {
    const stepsWithProps = React.Children.map(children, (child, index) => React.cloneElement(child, {
        on: activeIndex === index,
        activeIndex,
        maxIndex,
        next,
        prev,
    }));
    return stepsWithProps;
};

LatticeStepContainer.displayName = 'LatticeStepContainer';

export default LatticeStepContainer;
